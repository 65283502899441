import { render, staticRenderFns } from "./iconCamera.vue?vue&type=template&id=557d78b7&scoped=true"
import script from "./iconCamera.vue?vue&type=script&lang=js"
export * from "./iconCamera.vue?vue&type=script&lang=js"
import style0 from "./iconCamera.vue?vue&type=style&index=0&id=557d78b7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "557d78b7",
  null
  
)

export default component.exports