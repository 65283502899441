<template>
  <icon-base :width="size + 'px'" :fill="color">
    <g>
      <path class="st0" d="M199.2,71.7c0-11.6-8.2-21.1-19.6-23c-7.3-1.2-14.7-0.4-22-0.5c-2,0-3.2-0.6-4.3-2.3c-3-4.8-6.2-9.5-9.4-14.2   c-4.7-7-11.3-10.9-19.8-11c-16-0.1-32-0.1-48,0c-8.5,0.1-15.1,3.9-19.8,10.9c-3.2,4.8-6.5,9.6-9.6,14.5c-0.9,1.5-2,2-3.7,2   c-5.4-0.1-10.8,0-16.3,0C10.5,48.2,0.8,58,0.7,74c0,26.5,0,53,0,79.4c0,16.4,9.6,25.9,26,25.9c24.4,0,48.8,0,73.2,0   c24.8,0,49.6,0.1,74.4,0c14.4,0,24.8-9.8,24.8-23.6C199.3,127.7,199.3,99.7,199.2,71.7z M100,159.9c-29.5,0-53.5-23.8-53.5-53.3   c-0.1-29.5,23.8-53.3,53.4-53.3c29.5-0.1,53.5,23.8,53.6,53.3C153.6,135.9,129.6,159.8,100,159.9z M178.9,75.4   c-0.4,3.5-3,5.9-6.7,6.2c-1.5,0.1-3.1,0-4.6,0c0,0,0,0,0,0c-1.5,0-3.1,0.1-4.6,0c-3.8-0.3-6.3-2.7-6.6-6.3c-0.3-3.8,1.9-7,5.7-7.4   c3.7-0.4,7.5-0.4,11.1,0C176.9,68.2,179.3,72,178.9,75.4z"/>
      <path class="st0" d="M100,76.9c-16.3,0-29.7,13.4-29.7,29.8c0,16.4,13.4,29.7,29.9,29.6c16.2-0.1,29.4-13.4,29.5-29.6   C129.8,90.3,116.4,76.9,100,76.9z"/>
    </g>
  </icon-base>
</template>

<script>
export default {
  name: 'iconCamera',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

